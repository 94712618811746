export default {
    // force: [
    //     { title: 'smoking', value: 1, gender: [0, 1] },
    //     { title: 'religion', value: 2, gender: [0, 1] },
    //     { title: 'university', value: 3, gender: [0, 1] },
    // ],
    smoking: [
        { title: '상관없음', value: 0, gender: [0, 1] },
        { title: '비흡연', value: 1, gender: [0, 1] },
        { title: '흡연', value: 2, gender: [0, 1] },
    ],
    religion: [
        { title: '전부 괜찮아요', value: 0, gender: [0, 1] },
        { title: '무교', value: 1, gender: [0, 1] },
        { title: '기독교', value: 2, gender: [0, 1] },
        { title: '천주교', value: 3, gender: [0, 1] },
        { title: '불교', value: 4, gender: [0, 1] },
        { title: '기타', value: 5, gender: [0, 1] },
    ],
    job_detail: [
        { title: '전부 괜찮아요', value: 0, gender: [0, 1] },
        { title: '무직/취업준비/아르바이트', value: 1, gender: [0, 1] },
        { title: '대학원생 (석박사) /대학생', value: 2, gender: [0, 1] },
        { title: '프리랜서', value: 3, gender: [0, 1] },
        { title: '사업가 (개인사업/자영업)', value: 4, gender: [0, 1] },
        { title: '서비스직/영업직/판매직/현장직/생산직', value: 5, gender: [0, 1] },
        { title: '병원 (종합병원,대학병원,개인병원)', value: 6, gender: [0, 1] },
        { title: '연구직/기술직/교육직/의료직', value: 7, gender: [0, 1] },
        { title: '중소기업', value: 8, gender: [0, 1] },
        { title: '중견기업', value: 9, gender: [0, 1] },
        { title: '대기업/외국계 기업/금용기관', value: 10, gender: [0, 1] },
        { title: '스타트업', value: 11, gender: [0, 1] },
        { title: '공무원/공기업', value: 12, gender: [0, 1] },
        { title: '전문직/CEO', value: 13, gender: [0, 1] },
    ],
    school: [
        {
            title: '전부 괜찮아요',
            value: 0,
            gender: [0, 1],
        },
        {
            title: '고등학교 졸업',
            value: 1,
            // desc: '로스쿨, 의대(의전), 치대(치전), 한의대(한의전), 약대, 수의대',
            gender: [0, 1],
        },
        {
            title: '전문대',
            value: 2,
            // desc: '서울대, 연세대, 고려대, 카이스트, 포항공대',
            gender: [0, 1],
        },
        {
            title: '4년제 대학교',
            value: 3,
            // desc: '서강대, 성균관대, 한양대',
            gender: [0, 1],
        },
        {
            title: '인서울 / 지방거점국립대',
            value: 4,
            // desc: '중앙대, 경희대, 한국외대, 서울시립대',
            gender: [0, 1],
        },
        {
            title: '최고 명문대',
            value: 5,
            desc: 'SKY / 포카 / 아이비리그 등',
            gender: [0, 1],
        },
        {
            title: '명문대',
            value: 6,
            desc: '서성한 / 과학기술원 등',
            gender: [0, 1],
        },
    ],
    // income: [
    //     {
    //         title: '소득 관계 없음',
    //         value: 1,
    //         gender: [0, 1],
    //     },
    //     {
    //         title: '~ 2천만원대',
    //         value: 1,
    //         gender: [0, 1],
    //     },
    //     {
    //         title: '3~4천만원대',
    //         value: 2,
    //         gender: [0, 1],
    //     },
    //     {
    //         title: '5~7천만원대',
    //         value: 3,
    //         gender: [0, 1],
    //     },
    //     {
    //         title: '8~9천만원대',
    //         value: 4,
    //         gender: [0, 1],
    //     },
    //     {
    //         title: '1억원대',
    //         value: 5,
    //         gender: [0, 1],
    //     },
    //     {
    //         title: '2억원대 이상',
    //         value: 6,
    //         gender: [0, 1],
    //     },
    // ],
    // asset: [
    //     {
    //         title: '자산 관계 없음',
    //         value: 1,
    //         gender: [0, 1],
    //     },
    //     {
    //         title: '~ 5천만원',
    //         value: 1,
    //         gender: [0, 1],
    //     },
    //     {
    //         title: '5천만원~1억원대',
    //         value: 2,
    //         gender: [0, 1],
    //     },
    //     {
    //         title: '1~2억원대',
    //         value: 3,
    //         gender: [0, 1],
    //     },
    //     {
    //         title: '3~4억원대',
    //         value: 4,
    //         gender: [0, 1],
    //     },
    //     {
    //         title: '5~9억원대',
    //         value: 5,
    //         gender: [0, 1],
    //     },
    //     {
    //         title: '10억 이상',
    //         value: 6,
    //         gender: [0, 1],
    //     },
    // ],
    wealth: [
        {
            title: '소득 관계 없음',
            value: 0,
            category: 'income',
            gender: [0, 1],
        },
        {
            title: '~ 2천만원대',
            value: 1,
            category: 'income',
            gender: [0, 1],
        },
        {
            title: '3~4천만원대',
            value: 2,
            category: 'income',
            gender: [0, 1],
        },
        {
            title: '5~7천만원대',
            value: 3,
            category: 'income',
            gender: [0, 1],
        },
        {
            title: '8~9천만원대',
            value: 4,
            category: 'income',
            gender: [0, 1],
        },
        {
            title: '1억원대',
            value: 5,
            category: 'income',
            gender: [0, 1],
        },
        {
            title: '2억원대 이상',
            value: 6,
            category: 'income',
            gender: [0, 1],
        },
        {
            title: '자산 관계 없음',
            value: 10,
            category: 'asset',
            gender: [0, 1],
        },
        {
            title: '~ 5천만원',
            value: 11,
            category: 'asset',
            gender: [0, 1],
        },
        {
            title: '5천만원~1억원대',
            value: 12,
            category: 'asset',
            gender: [0, 1],
        },
        {
            title: '1~2억원대',
            value: 13,
            category: 'asset',
            gender: [0, 1],
        },
        {
            title: '3~4억원대',
            value: 14,
            category: 'asset',
            gender: [0, 1],
        },
        {
            title: '5~9억원대',
            value: 15,
            category: 'asset',
            gender: [0, 1],
        },
        {
            title: '10억 이상',
            value: 16,
            category: 'asset',
            gender: [0, 1],
        },
    ],
    university: [
        {
            title: '박사 졸업',
            value: 1,
            gender: [0, 1],
        },
        {
            title: '박사 재학',
            value: 2,
            gender: [0, 1],
        },
        {
            title: '석사 졸업',
            value: 3,
            gender: [0, 1],
        },
        {
            title: '석사 재학',
            value: 4,
            gender: [0, 1],
        },
        {
            title: '학사(4년제 대학) 졸업',
            value: 5,
            gender: [0, 1],
        },
        {
            title: '학사(4년제 대학) 재학',
            value: 6,
            gender: [0, 1],
        },
        {
            title: '학사(2,3년제 대학) 졸업',
            value: 7,
            gender: [0, 1],
        },
        {
            title: '학사(2,3년제 대학) 재학',
            value: 8,
            gender: [0, 1],
        },
        {
            title: '고등학교 졸업',
            value: 9,
            gender: [0, 1],
        },
    ],
    suggestion_method: [
        {
            title: '둘다 소개받기',
            desc: '매칭 가능성이 높은 회원 & 인기 회원 모두 소개받을래요!',
            value: 0,
            gender: [0, 1],
        },
        {
            title: '매칭 가능성 높은 회원만 소개받기',
            desc: '나랑 비슷한 인기도를 가진 회원만 소개받을래요!',
            value: 1,
            gender: [0, 1],
        },
        {
            title: '인기 회원만 소개받기',
            desc: '인기 많은 회원만 소개받을래요!<br />',
            desc2: '* 매칭 시 만남권 2장 이상이 사용될 수 있습니다',
            value: 2,
            gender: [0, 1],
        },
    ],
    marry_plan: [
        { title: '전부 괜찮아요', value: 0, gender: [0, 1] },
        { title: '가급적 빨리 하고싶어요', value: 1, gender: [0, 1] },
        { title: '1~2년 정도 연애하고 결혼하고 싶어요', value: 2, gender: [0, 1] },
        { title: '3~4년 정도 연애하고 결혼하고 싶어요', value: 3, gender: [0, 1] },
        { title: '마음에 드는 사람을 만나면 언제든 가능해요', value: 4, gender: [0, 1] },
        { title: '결혼할만한 상대와 연애하고 싶지만, 결혼 시기는 여유롭게 생각하고 있어요', value: 5, gender: [0, 1] },
    ],
    force: [
        { title: '외모', value: 1, gender: [0, 1] },
        { title: '나이', value: 2, gender: [0, 1] },
        { title: '키', value: 3, gender: [0, 1] },
        { title: '지역', value: 4, gender: [0, 1] },
        { title: '커리어(직업/직장)', value: 5, gender: [0, 1] },
        { title: '학력', value: 6, gender: [0, 1] },
        { title: '경제력', value: 7, gender: [0, 1] },
        { title: '종교', value: 8, gender: [0, 1] },
        { title: '결혼계획', value: 9, gender: [0, 1] },
    ],
    marry: [
        {
            title: '상관없음',
            value: 0,
            gender: [0, 1],
        },
        {
            title: '미혼만 소개받기',
            value: 1,
            gender: [0, 1],
        },
        {
            title: '돌싱만 소개받기',
            value: 2,
            gender: [0, 1],
        },
    ],
}
