import { render, staticRenderFns } from "./EditStylePage.vue?vue&type=template&id=40be7166&scoped=true"
import script from "./EditStylePage.vue?vue&type=script&lang=js"
export * from "./EditStylePage.vue?vue&type=script&lang=js"
import style0 from "./EditStylePage.vue?vue&type=style&index=0&id=40be7166&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40be7166",
  null
  
)

export default component.exports